import React from "react"
import PropTypes from "prop-types"
import "../assets/css/hero.scss"
import { FixedImage } from "../services/images"

const SearchHero = ({
  data,
  title,
  className = "",
  options,
  onSearch = () => {},
}) => {
  let background = ""
  if (data && data.image) {
    background = data.image.image.id
  }

  const search = ev => {
    if (onSearch) {
      onSearch(ev.target.value.toLowerCase())
    }
  }

  if (background) {
    return (
      <section className={`hero search ${className}`}>
        <FixedImage src={background} options={options} />
        {title !== "" && (
          <div className="text">
            <h1 className={title[0].toLowerCase()}>{title}</h1>
            <div className="divider"></div>
          </div>
        )}
        <section className="search-container">
          <input type="text" placeholder="Search" onChange={search} />
        </section>
      </section>
    )
  }
  return <div></div>
}

SearchHero.defaultProps = {
  data: {},
  title: "",
}

SearchHero.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
}

export default SearchHero
