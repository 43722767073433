import React from "react"
import { Link } from "gatsby"
import { FixedImage } from "../services/images"

export default ({ article = {} }) => {
  return (
    <article>
      <Link to={`/${article.url}`}>
        {article.image && article.image.image && (
          <FixedImage
            src={article.image.image.id}
            alt={article.image.alt || article.pageTitle}
            key={article.image.image.id}
            options={{
              fit: "contain",
              gravity: article.image.image.gravity || "center",
            }}
          />
        )}
      </Link>
      <div className="article-detail">
        <h3>
          <Link to={`/${article.url}`} title={article.pageTitle}>
            {article.pageTitle}
          </Link>
        </h3>
      </div>
    </article>
  )
}
