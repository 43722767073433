import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import "../assets/css/lookbook.scss"
import Layout from "../components/layout"
import SearchHero from "../components/searchHero"
import FullCTA from "../components/fullCTA"
import ArticlePreview from "../components/articlePreview"

const Lookbook = ({ data, location }) => {
  let page = location.hash ? parseInt(location.hash.split("-")[1]) : 0
  let pageSize = 8
  let start = page * pageSize
  const articles = data.tst.allArticles.slice(start, start + pageSize)
  let [searchArticles, setSearchArticles] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0, {
      behavior: "smooth",
    })
  })

  const article = data.tst.Page || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  article.pageMetaData.url = article.url
  let timeout
  return (
    <Layout meta={article.meta}>
      <SearchHero
        data={article.hero || {}}
        title={article.pageTitle || article.name}
        onSearch={key => {
          clearTimeout(timeout)
          setTimeout(() => {
            let articles = data.tst.allArticles.filter(article => {
              return (
                article.pageTitle.toLowerCase().includes(key) ||
                article.text.toLowerCase().includes(key)
              )
            })
            setSearchArticles(articles)
          }, 300)
        }}
      />
      <section className="articles py-xl">
        {(searchArticles.length > 0 ? searchArticles : articles).map(
          (article, idx) => {
            return <ArticlePreview article={article} key={`article-${idx}`} />
          }
        )}
      </section>
      <div className="nav display-flex">
        <div className="previous">
          {page - 1 >= 0 && (
            <Link
              to={`/the-stil-lookbook#page-${page - 1}`}
            >{`< Previous`}</Link>
          )}
        </div>
        <div className="next">
          {page < Math.floor(data.tst.allArticles.length / pageSize) && (
            <Link to={`/the-stil-lookbook#page-${page + 1}`}>{`Next >`}</Link>
          )}
        </div>
      </div>
      {article.ctas && article.ctas.map(cta => <FullCTA data={cta} />)}
    </Layout>
  )
}

export const query = graphql`
  query LookbookQuery($id: ID!) {
    tst {
      Page(where: { id: $id }) {
        name
        pageTitle
        text
        url
        ctas {
          ...CTA
        }
        ctasSort
        features {
          ...Feature
        }
        hero {
          ...Hero
        }
        pageMetaData {
          ...Meta
        }
      }
      allArticles(sortBy: postedAt_DESC) {
        pageTitle
        url
        text
        image {
          ...Image
        }
      }
    }
  }
`

export default Lookbook
